/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram , faFacebook} from '@fortawesome/free-brands-svg-icons'


import Header from "./header"
import "./layout.css"

const Layout = ({ children , home, lang }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      courseDescriptions {
        id
        tittle
        menuH {
          auto
          title
          type
        }
      },
      allHeaders {
        edges {
          node {
            id
            title
            prefix
            multiple
            link
          }
        }
      },
      allRooms {
        edges {
          node {
            id
            title
          }
        }
      },
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
       <Header lang={lang} home={home} siteTitle={data.courseDescriptions.tittle} menu={data.allHeaders} allRoom={data.allRooms} />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer style={{marginBottom:0,backgroundColor: 'rgb(245, 241, 234)'}}>
          <div style={{backgroundColor: 'rgb(245, 241, 234)',display: 'flex',padding: 30,maxWidth: 980,marginLeft: 'auto',marginRight: 'auto'}}>
            {/*© {new Date().getFullYear()}, Built by
            {` `}
            <a href="https://">cainoeraabele</a>*/}
            <div style={{width:'50%'}}>
              <p style={{margin:0}}><strong>Demidoff Hotel</strong> Via Plinio, 2, 20129 Milano  T.<a href="tel:+390229513889">(+39) 0229513889</a> - Mail: <a href="mailto:demidoff@milanohotels.com">demidoff@milanohotels.com</a></p>
            </div>
            <div style={{textAlign:'right',width:'50%'}} className="follow">
                <span style={{marginRight:20}}>FOLLOW US</span>
                <a href="https://www.facebook.com/demidoffhotelmilano/" style={{margin:5}}><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://www.instagram.com/demidoffhotelmilano/" style={{margin:5}}><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
