import React from "react"
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';


class Selector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: undefined,
      to: undefined,
      abult:undefined,
      children:undefined,
      finderSelect:undefined
    };


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);

    this.handleChangeAdult = this.handleChangeAdult.bind(this);
  }

  fbFunction(){

    console.log(this.state);
    var dd = this.state.from.getDate();
    var mm = this.state.from.getMonth() + 1; //January is 0!
    var yyyy = this.state.from.getFullYear();

    var timeDiff = Math.abs(this.state.from.getTime() - this.state.to.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    //alert(diffDays);

    window.open('https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml?showPromotions=1&Clusternames=ITMILHTLDemidoffHote&Hotelnames=ITMILHTLDemidoffHote&fromday='+dd+'&frommonth='+mm+'&fromyear='+yyyy+'&nbdays='+diffDays+'&adulteresa='+this.state.abult+'&AccessCode=', '_blank')
    //alert(dd+'-'+mm+'-'+yyyy)
    //()=> window.open('https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml?showPromotions=1&Clusternames=ITMILHTLDemidoffHote&Hotelnames=ITMILHTLDemidoffHote&fromday=26&frommonth=6&fromyear=2019&nbdays=5&adulteresa=3&AccessCode=', '_blank')
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from }, () => {
      if (!this.state.to) {
        //this.focusTo();
      }
    });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  handleSubmit(){
  /*  console.log('culo');
    console.log(this.state);
    var from = this.state.from;
    var to = this.state.to;
    var stringFrom = this.dateToString(from);
    var stringTO = this.dateToString(to)
    console.log(stringFrom);
    console.log(stringTO);*/
    //this.setState({finderSelect:true});

    this.props.onClick(this.state);







  }

  handleChangeAdult(event) {
    this.setState({abult: event.target.value});
  }


  render() {
    const today = new Date();
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div style={{zIndex: '999', position: 'relative', background: '#50505087',padding: 10}}>
        <span className="abult">
          <select style={{position: 'relative',marginRight: 10,
    top: '-2px',height:41,fontSize:15,fontWeight: '600',
  color: '#c6c6c6',border:'none'}} className="form-control" value={this.state.abult} onChange={this.handleChangeAdult}>
            <option value={0} >Adulti</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>
        </span>
        <span className="InputFromTo-to">
        <DayPickerInput
          inputProps={{ readOnly: true }}
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: today },
            toMonth: to,
            modifiers,
            numberOfMonths: 1,
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        </span>
        <span className="InputFromTo-to">
          <DayPickerInput
            inputProps={{ readOnly: true }}
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from  },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
            }}
            onDayChange={this.handleToChange}
          />
          </span>

          {/*<span className="abult">

            <input style={{
              width:61,
              marginLeft:10
            }} type='number' placeholder='Adulti'></input>
          </span>*/}

          {/*<span className="baby">
            <select style={{height:41,fontSize:15,fontWeight: '600',
    color: '#c6c6c6',border:'none'}} className="form-control" value={this.state.children} onChange={this.handleChangechildren}>
              <option value={0}>Bambini(0-3 anni)</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            {/*<input style={{
              width:61,
              marginLeft:10
            }} type='number' placeholder='Bambini'></input>*/}
          {/*</span> <FlatButton fullWidth={true} className='goButton' onClick={this.handleSubmit}  label="Verifica disponibilità"  backgroundColor={'#f7c9ca'}  labelColor={'#ffffff'}/>*/}
          <div style={{backgroundColor:'#f7c9ca',height: 42,marginTop:5}} ><button style={{backgroundColor:'#f7c9ca',color:'#000',fontWeight: '800',border:'none',cursor:'pointer',width: '100%',paddingTop:10}} fullWidth={true} className='goButton' onClick={this.fbFunction.bind(this)}   backgroundColor={'#f7c9ca'}  labelColor={'#ffffff'}>Verifica disponibilità</button></div>
              </div>
            );
  }


}


export default Selector
