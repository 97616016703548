import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from './logdf.png'
import itaFlag from "../images/italy-flag-round-icon-16.png"
import enFlag from "../images/united-kingdom-flag-round-icon-16.png"



const Header = ({ siteTitle , menu , allRoom , home,lang }) => (
  <header
    style={{
      //background: `#f7c9c9`,
      background: `#ffffff`,
      marginBottom: `1.45rem`,
    }}
  >
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<link href="https://fonts.googleapis.com/css?family=Big+Shoulders+Text|Dosis|Montserrat|Quicksand|Slabo+27px|Roboto+Condensed&display=swap" rel="stylesheet"/>
  <script language="javascript" id="visitor_id" src="https://bscripts.guestinbox.com/base_gtm.js?hotel_ref=FB-57617865"></script>



  <div
      style={{
        margin: `0 auto`,
        //maxWidth: 960,
        //padding: `1.45rem 1.0875rem`,
      }}
    >
      <div class='menu-container'>

        <div class='menu'>
          <div class='date'>
            <Link
                to={lang ? 'en' : '/'}
                style={{
                  color: `black`,
                  textDecoration: `none`,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              ><img alt='logo' style={{width: 34}} src={Logo} />
            <h1 style={home ? {textTransform: 'capitalize', fontSize: 23, color: '#ffffff',height: 0,marginLeft: 10} :{textTransform: 'capitalize', fontSize: 23, color: '#a8626a',height: 0,marginLeft: 10}}>{siteTitle}</h1>
        </Link>
        </div>
        <div class='date'>
        <ul style={{display:'flex',textTransform:'uppercase'}}>
          {console.log(menu)}
          {menu.edges.map(function(item, i){
            console.log(item);
          if(lang){
            return (
              <Link
                to={'/en/'+item.node.title}
                style={{
                  color: `black`,
                  textDecoration: `none`,
                }}
              >
              <li style={home ? {color:'white'} : {}} key={i}>{item.node.title}</li>
              </Link>
            )
          }else {
            return (
              <Link
                to={'/'+item.node.title}
                style={{
                  color: `black`,
                  textDecoration: `none`,
                }}
              >
              <li style={home ? {color:'white'} : {}} key={i}>{item.node.title}</li>
              </Link>
            )
          }

          })}
          {lang ? (
            <Link
              to={'/'}
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >
            <li style={home ? {color:'white'} : {}} >it <img style={{width:10}} src={itaFlag} /></li>
            </Link>
          ) : (
            <Link
              to={'/en'}
              style={{
                color: `black`,
                textDecoration: `none`,
              }}
            >
            <li style={home ? {color:'white'} : {}} >en <img style={{width:10}} src={enFlag} /></li>
            </Link>
          )}
        </ul>
        </div>
          <div class='links'>

          </div>
        </div>

    </div>
      {/*<Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        ><div style={{textAlign:'center'}}>
        <img style={{width: 54}} src={Logo} />
        <h1 style={{textTransform: 'capitalize', fontSize: 23}}>{siteTitle}</h1>
        </div>
        </Link>
      <ul>
        {menu.map(function(item, i){
        return (
          <Link
            to={'/'+item.title}
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
          <li key={i}>{item.title}</li>
          </Link>
        )
        })}
      </ul>*/}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
